import React, { useState, useCallback, useEffect } from "react";
import Latex from "react-latex";
import SiteHelmet from "~components/site-helmet";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import color from "~styles/color";
import { Code, CodeHighlight } from "~components/documentation";

import Footer from "~components/footer";
import { ExtendedHeader } from "~components/header";
import { HomeTitle } from "~components/text-helpers";

import { Menu, Button, Table } from "antd";
const { SubMenu } = Menu;

import {
  CopyFilled,
  CheckOutlined,
  DownOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

function EventFrame(props: { caption: string; image: string }) {
  return (
    <div
      css={css`
        background-color: ${color.gray2};
        border: 1px solid ${color.gray5};
        overflow: hidden;
        border-radius: 5px;
        display: inline-block;
        width: 220px;
        text-align: left;
        margin-left: 5px;
        margin-right: 5px;
      `}
    >
      <div
        css={css`
          font-family: "Inconsolata";
          padding-top: 8px;
          padding-bottom: 8px;
          padding-left: 10px;
          font-size: 12px;
        `}
      >
        {props.caption}
      </div>
      <div
        css={css`
          position: relative;
          height: 218px;
        `}
      >
        <div
          css={css`
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px;
            background-color: ${color.gray8};
            position: absolute;
          `}
        />
        <img
          src={props.image}
          css={css`
            width: 100%;
            position: absolute;
            top: 0px;
          `}
        />
      </div>
    </div>
  );
}

function GetStarted() {
  const [scene, setScene] = useState([1, 0]);

  const imageCycle = () => {
    setScene((i: number[]) => [(i[0] % 10) + 1, 0]);
    setTimeout(() => setScene((i: number[]) => [i[0], 1]), 1000);
    setTimeout(() => setScene((i: number[]) => [i[0], 2]), 2000);
    setTimeout(() => setScene((i: number[]) => [i[0], 3]), 3000);
    setTimeout(() => setScene((i: number[]) => [i[0], 4]), 4000);
  };

  useEffect(() => {
    imageCycle();
    setInterval(() => {
      imageCycle();
    }, 5000);
  }, []);

  let h1: React.ReactNode;
  switch (scene[0]) {
    case 10:
      h1 = (
        <CodeHighlight
          line={8}
          colStart={18 + (scene[0] - 1) * 3}
          colEnd={18 + (scene[0] - 1) * 3 + 2}
        />
      );
      break;
    default:
      h1 = (
        <CodeHighlight
          line={8}
          colStart={18 + (scene[0] - 1) * 3}
          colEnd={18 + (scene[0] - 1) * 3 + 1}
        />
      );
      break;
  }

  let h2: React.ReactNode;
  switch (scene[1]) {
    case 0:
      h2 = <CodeHighlight line={9} colStart={4} colEnd={9} />;
      break;
    case 1:
      h2 = <CodeHighlight line={10} colStart={19} colEnd={30} />;
      break;
    case 2:
      h2 = <CodeHighlight line={10} colStart={32} colEnd={45} />;
      break;
    case 3:
      h2 = <CodeHighlight line={10} colStart={47} colEnd={55} />;
      break;
    case 4:
      h2 = <CodeHighlight line={10} colStart={57} colEnd={65} />;
      break;
  }

  return (
    <div
      css={css`
        margin: auto;
        margin-bottom: 50px;
        text-align: center;
      `}
    >
      <div
        css={css`
          display: inline-block;
          vertical-align: middle;
          max-width: 500px;
          text-align: left;
          position: relative;
          > div {
            margin-top: 0px;
          }
        `}
      >
        {h1}
        {h2}
        <Code
          lines={[
            "from ai2thor.controller import Controller",
            "",
            "controller = Controller(",
            "    renderDepthImage=True,",
            "    renderInstanceSegmentation=True",
            ")",
            "",
            "for kitchen_i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]:",
            '    event = controller.reset(scene=f"FloorPlan{kitchen_i}")',
            '    for action in ["MoveAhead", "RotateRight", "LookUp", "Crouch"]:',
            "        event = controller.step(action=action)",
          ]}
        />
      </div>
      <div
        css={css`
          display: inline-block;
          vertical-align: middle;
          margin-left: 25px;
        `}
      >
        <EventFrame
          caption="event.frame"
          image={`/media/home/FloorPlan${scene[0]}_${scene[1]}.png`}
        />
        <EventFrame
          caption="event.depth_frame"
          image={`/media/home/FloorPlan${scene[0]}_depth_${scene[1]}.png`}
        />
        <EventFrame
          caption="event.instance_segmentation_frame"
          image={`/media/home/FloorPlan${scene[0]}_i${scene[1]}.png`}
        />
      </div>
    </div>
  );
}

/**
 * Home page comparisons between RoboTHOR and iTHOR as environments.
 * @param props.name
 * @param props.children contains the list of all features
 * that differentiates each environment.
 * @param props.cover The global path to the Environment's cover.
 */
function Environment(props: {
  name: string;
  cover: string;
  features: string[];
  path: string;
}) {
  const [envHovered, setEnvHovered] = useState(false);

  return (
    <div
      css={css`
        width: 625px;
        height: 730px;
        @media (max-width: 700px) {
          width: 90%;
          height: 800px;
        }
        @media (max-width: 400px) {
          width: 90%;
          height: 950px;
        }
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        color: ${color.gray10};
        background-color: white;
        display: inline-block;
        text-align: left;
        vertical-align: top;
        margin: 0px 20px;
        margin-bottom: 35px;
        position: relative;
      `}
      onMouseEnter={() => setEnvHovered(true)}
      onMouseLeave={() => setEnvHovered(false)}
    >
      <div
        css={css`
          height: 24em;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            height: 27em;
            position: relative;
            overflow: hidden;
          `}
        >
          <Link to={props.path}>
            <div
              css={css`
                background-color: ${color.gray10};
                background-image: url("${props.cover}");
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-size: cover;
                margin-bottom: 20px;
                transition-duration: 0.3s;
                ${envHovered
                  ? css`
                      transform: scale(1.025);
                      background-position: 50% 92%;
                    `
                  : css`
                      background-position: 50% 93%;
                      transform: scale(1);
                    `}
              `}
            />
          </Link>
        </div>
      </div>
      <div
        css={css`
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 10px;
        `}
      >
        <div
          css={css`
            font-size: 2.5em;
            font-weight: 600;
            margin-bottom: 5px;
          `}
        >
          {props.name}
        </div>
        <ul>
          {props.features.map((feature: string) => (
            <li
              css={css`
                font-size: 16px;
              `}
            >
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <Link to={props.path}>
        <Button
          type="primary"
          css={css`
            font-size: 16px;
            height: 40px;
            position: absolute;
            bottom: 25px;
            background-color: ${color.light.blue6};
            left: 20px;
            &:hover {
              background-color: ${color.light.blue8};
            }
          `}
        >
          Explore {props.name}{" "}
          <ArrowRightOutlined
            css={css`
              font-size: 13px;
              display: inline-block !important;
              vertical-align: middle;
              margin-top: -5px;
            `}
          />
        </Button>
      </Link>
    </div>
  );
}

/**
 *
 * @param props.month
 */
function BlogEntry(props: {
  month: string;
  year: number;
  title: string;
  path: string;
  description: string;
  background: css;
}) {
  const innerContent = (
    <div
      css={css`
        width: 400px;
        height: 500px;
        @media (max-width: 1300px) {
          width: 90%;
          height: 350px;
        }
        border-radius: 5px;
        box-shadow: 0px 1px 20px ${color.gray2};
        position: relative;
        display: inline-block;
        margin-left: 20px;
        margin-right: 20px;
        color: white;
        text-align: left;
        background: green;
        vertical-align: top;
        margin-bottom: 30px;
        position: relative;
        ${props.background}
        background-size: cover;
        background-position: center;
        transition-duration: 0.3s;
        &:hover {
          filter: contrast(1.35);
          cursor: pointer;
        }
      `}
    >
      <div
        css={css`
          left: 5%;
          width: 90%;
          position: absolute;
          bottom: 20px;
        `}
      >
        <div
          css={css`
            font-size: 33px;
            line-height: 35px;
            font-weight: 600;
          `}
        >
          {props.title}
        </div>
        <div
          css={css`
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-top: 5px;
          `}
        >
          {props.month} {props.year}
        </div>
        <div
          css={css`
            margin-top: 10px;
            font-size: 15px;
            /* background: orange; */
            height: 150px;
          `}
        >
          {props.description}
        </div>
      </div>
    </div>
  );
  return props.path.startsWith("//") ? (
    <a href={props.path} target="_blank">
      {innerContent}
    </a>
  ) : (
    <Link to={props.path}>{innerContent}</Link>
  );
}

function FrameworkComparisonTable() {
  const [showDefinitions, setShowDefinitions] = useState(false);
  return (
    <div
      css={css`
        padding-top: 50px;
        padding-bottom: 20px;
      `}
    >
      <HomeTitle title="Framework Comparison" />
      <Table
        css={css`
          max-width: 1350px;
          padding-left: 2%;
          padding-right: 2%;
          margin: auto;
          th,
          td {
            text-align: center !important;
          }

          th {
            line-height: 20px;
          }

          tr th:nth-of-type(1),
          tr td:nth-of-type(1) {
            text-align: left !important;
          }
          tr:nth-of-type(2) td {
            font-weight: 600 !important;
          }
        `}
        scroll={{ x: 1250 }}
        pagination={false}
        columns={[
          {
            title: "Environment",
            dataIndex: "Environment",
            key: "Environment",
            fixed: "left",
            width: 150,
          },
        ].concat(
          // @ts-ignore: not containing fixed is find
          [
            "Navigable",
            "3D Scene Scans",
            "3D Asset Library",
            "Physics-Based Interaction",
            "Object States",
            "Object Specific Reactions",
            "Dynamic Lighting",
            "Multiple Agents",
            "Real Counterpart",
          ].map((column: string) => ({
            title: column,
            dataIndex: column,
            key: column,
          }))
        )}
        dataSource={[
          {
            key: "ai2-thor",
            Environment: "AI2-THOR",
            Navigable: "✓",
            "3D Scene Scans": "",
            "3D Asset Library": "✓",
            "Physics-Based Interaction": "✓",
            "Object States": "✓",
            "Object Specific Reactions": "✓",
            "Dynamic Lighting": "✓",
            "Multiple Agents": "✓",
            "Real Counterpart": "✓",
          },
          {
            key: "igibson",
            Environment: "iGibson",
            Navigable: "✓",
            "3D Scene Scans": "✓",
            "3D Asset Library": "",
            "Physics-Based Interaction": "✓",
            "Object States": "",
            "Object Specific Reactions": "",
            "Dynamic Lighting": "",
            "Multiple Agents": "✓",
            "Real Counterpart": "",
          },
          {
            key: "habitat",
            Environment: "Habitat",
            Navigable: "✓",
            "3D Scene Scans": "✓",
            "3D Asset Library": "",
            "Physics-Based Interaction": "Collisions",
            "Object States": "",
            "Object Specific Reactions": "",
            "Dynamic Lighting": "",
            "Multiple Agents": "",
            "Real Counterpart": "",
          },
          {
            key: "matterport3d",
            Environment: "Matterport3D",
            Navigable: "✓",
            "3D Scene Scans": "✓",
            "3D Asset Library": "",
            "Physics-Based Interaction": "",
            "Object States": "",
            "Object Specific Reactions": "",
            "Dynamic Lighting": "",
            "Multiple Agents": "",
            "Real Counterpart": "",
          },
          {
            key: "minos",
            Environment: "Minos",
            Navigable: "✓",
            "3D Scene Scans": "✓",
            "3D Asset Library": "",
            "Physics-Based Interaction": "",
            "Object States": "",
            "Object Specific Reactions": "",
            "Dynamic Lighting": "",
            "Multiple Agents": "",
            "Real Counterpart": "",
          },
        ]}
      />
      <div
        css={css`
          text-align: center;
          margin-top: 20px;
        `}
      >
        <div
          css={css`
            display: inline-block;
            margin: auto;
            color: ${showDefinitions ? color.gray8 : color.gray6};
            font-weight: 500;
            transition-duration: 0.3s;
            &:hover {
              cursor: pointer;
              color: ${showDefinitions ? color.gray10 : color.gray7};
            }
          `}
          onClick={() => setShowDefinitions((s) => !s)}
        >
          Definitions{" "}
          <DownOutlined
            css={css`
              font-size: 12px;
              transition-duration: 0.3s;
              transform: ${showDefinitions ? "rotate(180deg)" : "rotate(0deg)"};
            `}
          />
        </div>
        {!showDefinitions ? (
          <></>
        ) : (
          <Table
            css={css`
              transition-duration: 0.3s;
              width: 1300px;
              margin: auto;
              margin-top: 10px;

              th {
                display: none;
              }
            `}
            pagination={false}
            columns={[
              {
                title: "Column",
                dataIndex: "Column",
                key: "Column",
                fixed: "left",
              },
              {
                title: "Description",
                dataIndex: "Description",
                key: "Description",
                fixed: "left",
              },
            ]}
            dataSource={[
              {
                key: "Navigable",
                Column: "Navigable",
                Description:
                  "Agents can move around different scenes in simulation.",
              },
              {
                key: "3d-scans",
                Column: "3D Scene Scans",
                Description:
                  "High-fidelity 360 degree image scans of the scenes.",
              },
              {
                key: "3d-asset",
                Column: "3D Asset Library",
                Description:
                  "Downloadable individual objects as .fbx or .obj file. Reconfiguration and randomization of objects within the scenes.",
              },
              {
                key: "Physics-Based Interaction",
                Column: "Physics-Based Interaction",
                Description:
                  "Gravity, friction, normal, and drag forces. Force-based manipulation of objects such as throw, drop, push, pull.",
              },
              {
                key: "Object States",
                Column: "Object States",
                Description:
                  "Object state changes, such as cooking a raw potato, turning on the lights, opening and closing drawers, and slicing an apple. Contextual object state changes, such as a lit candle under running water will go out, turning on a coffee machine with an empty mug will fill the mug and make the mug hot.",
              },
              {
                key: "Object Specific Reactions",
                Column: "Object Specific Reactions",
                Description:
                  "Reactive collisions, such as dropping on a mug on the floor may cause it to break, while dropping a basketball would result in it bounding on the floor. Conservation of energy, such as a dropped basketball not bouncing forever.",
              },
              {
                key: "Dynamic lighting",
                Column: "Dynamic lighting",
                Description:
                  "Object shadows casted in real time, relative to light sources. Manipulation of light sources, such as turning on and off a lamp.",
              },
              {
                key: "Multiple Agents",
                Column: "Multiple Agents",
                Description: "Handling of several agents operating in a scene.",
              },
              {
                key: "Real Counterpart",
                Column: "Real Counterpart",
                Description:
                  "Corresponding scenes in simulation and real life. Remote access to the real environment.",
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <div>
      <SiteHelmet>
        <title>{`AI2-THOR`}</title>
        <meta
          name="keywords"
          content="AI2-THOR, Embodied AI, Python, Reinforcement Learning, Computer Vision, Artificial Intelligence, Allen Institute for AI"
        />
        <meta
          name="description"
          content="Open Source Interactive Environments for Embodied AI"
        />
      </SiteHelmet>
      <ExtendedHeader videoLink="https://player.vimeo.com/video/508999827" />
      {/* <div
        css={css`
          background-color: ${color.gray2};
          padding-top: 25px;
          padding-bottom: 30px;
          @media (max-width: 1280px) {
            display: none;
          }
        `}
      >
        <HomeTitle title="Get Started" />
        <div
          css={css`
            text-align: center;
            margin: auto;
            margin-bottom: 35px;
          `}
        >
          <p
            css={css`
              margin-top: -28px;
              font-size: 15px;
            `}
          >
            AI2-THOR is a simulation framework used to facilitate research
            <br />
            in Embodied AI. We provide an easy to use Python API.
          </p>
          <a
            href="//colab.research.google.com/github/allenai/ai2thor-colab/blob/main/templates/AI2_THOR_Full_Starter_Template.ipynb"
            target="_blank"
          >
            <Button
              css={css`
                background-color: ${color.light.blue6};
                color: white;
                height: 40px;

                &:active,
                &:visited,
                &:focus,
                &:hover {
                  border-color: ${color.gray2} !important;
                  color: white !important;
                  background-color: ${color.light.blue8};
                }
              `}
            >
              Open in Colab <ArrowRightOutlined />
            </Button>
          </a>
        </div>
        <GetStarted />
      </div> */}
      <div
        css={css`
          background-color: ${color.gray3};
          padding-top: 30px;
          padding-bottom: 30px;
        `}
      >
        <HomeTitle title="Environments" />
        <div
          css={css`
            text-align: center;
          `}
        >
          <Environment
            name="iTHOR"
            cover="/media/iTHOR-cover.jpg"
            path="/ithor"
            features={[
              "120 rooms including kitchens, bedrooms, bathrooms and living rooms",
              "Over 2000 unique objects",
              "Based on Unity 3D which enables physical simulation for objects and scenes",
              "Includes objects with visual state changes including open/close, on/off, hot/cold",
              "Support for multiple agent types such as humanoids and drones",
              "Support for multiple agents in a single scene",
            ]}
          />
          <Environment
            name="RoboTHOR"
            cover="/media/RoboTHOR-cover.jpg"
            path="/robothor"
            features={[
              "89 apartments with 600+ objects",
              "Physical and simulated counterparts for 14 apartments",
              "Uses the LoCoBot robot with a Kinect camera",
              "Apartments built in a modular fashion drawing from an asset library",
              "Physical apartments are easily reconfigurable",
              "Simulation and real environments accessible to all",
            ]}
          />
        </div>
      </div>
      <FrameworkComparisonTable />
      <div
        css={css`
          padding-top: 40px;
          margin-top: 40px;
          padding-bottom: 40px;
          background-color: ${color.gray2};
        `}
      >
        <HomeTitle title="Latest Updates" />
        <div
          css={css`
            text-align: center;
          `}
        >
          <BlogEntry
            title="Cloud Rendering"
            month="February"
            path="/ithor/documentation/#headless-setup"
            year={2022}
            description="AI2-THOR now supports headless rendering, making it easier and more accessible to use on computing clusters."
            background={css`
              background: linear-gradient(
                  to bottom left,
                  ${color.dark.volcano6 + "cc"},
                  ${color.dark.volcano5 + "ee"}
                ),
                url("/media/cover3.jpg");
            `}
          />
          <BlogEntry
            title="ManipulaTHOR Release"
            month="April"
            path="/manipulathor"
            year={2021}
            description="We are excited to release ManipulaTHOR, an environment within AI2-THOR that facilitates visual manipulation of objects using a robotic arm."
            background={css`
              background: linear-gradient(
                  to bottom left,
                  ${color.dark.magenta6 + "cc"},
                  ${color.dark.magenta5 + "ee"}
                ),
                url("/media/cover2.jpg");
            `}
          />
          <BlogEntry
            title="AI2-THOR v2.7.0"
            month="February"
            path="//medium.com/ai2-blog/speed-up-your-training-with-ai2-thor-2-7-0-12a650b6ab5e"
            year={2021}
            description="AI2-THOR v2.7.0 announces several massive speedups on to AI2-THOR!"
            background={css`
              background: linear-gradient(
                  to bottom left,
                  ${color.dark.purple6 + "cc"},
                  ${color.dark.purple5 + "ee"}
                ),
                url("/media/cover1.jpg");
            `}
          />
        </div>
        <div
          css={css`
            text-align: center;
          `}
        >
          {/* <Link to="/blog">
            <div
              css={css`
                display: inline-block;
                color: ${color.gray7};
                font-weight: 600;
                margin-top: 15px;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 5px;
                transition-duration: 0.3s;
                padding-bottom: 5px;
                &:hover {
                  color: ${color.gray8};
                }
              `}
            >
              View More
            </div>
          </Link> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
